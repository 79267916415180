import React from "react";
import {StaticImage} from "gatsby-plugin-image"

import LayoutWidget from "../../../components/widgets/layout";
import Seo from "../../../components/seo";

const Demo4Page = () => {

    return (
        <LayoutWidget>
            <Seo
                seo={{
                    metaTitle: "Demo",
                    metaDescription: "It looks like you got to the demo page",
                }}
            />

            <div className="hidden mb-16 text-center lg:block">
                <StaticImage
                    src="../../../images/tesla.jpg"
                    alt="Tesla red"
                    layout="constrained"
                    width={1440}
                    quality="80"
                />
            </div>

            <div
                className="p-4 mb-12 lg:flex lg:max-w-[1440px] mx-auto lg:space-x-12 items-center lg:px-8 xl:px-10">
                <rtr-article article-id="8B83RM" booking-view="calendar"></rtr-article>
            </div>

        </LayoutWidget>
    )
};

export default Demo4Page;
